import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from '../models/login'
import { TokenModel } from '../models/token.model'
import {NhanVienCreate} from "@/models/nhanVienCreate";
import PasswordModel from "@/models/password.model";
import {NhanVienPermission} from "@/models/permission.model";
import {NhanVien} from "@/models/nhanVien";

const resource = '/auth'

export default new class {
  auth (login: Login): Promise<AxiosResponse<TokenModel>> {
    console.log('############################## loginpage auth api')
    return Repository.post<TokenModel>(`${resource}/login`, login)
  }
  dangKyNhanVien(nhanVienCreate: NhanVienCreate): Promise<any>{
    return Repository.post<any>(`${resource}/taotaikhoan`, nhanVienCreate);
  }
  changePassword(changePassword: PasswordModel): Promise<any>{
    return Repository.post<TokenModel>(`${resource}/password`, changePassword);
  }
  userPermission(login: Login): Promise<AxiosResponse<NhanVienPermission>>{
    return Repository.post<NhanVienPermission>(`${resource}/permission`, login);
  }
  dsNhanVien(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/dstaikhoan`);
  }
  capNhatNhanVien(nhanVien: NhanVien): Promise<AxiosResponse<any>>{
    return Repository.put<any>(`${resource}/capnhattaikhoan`, nhanVien);
  }
  xoaNhanVien(id: any): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/xoataikhoan/${id}`);
  }
  chiTietNhanVien(id: any): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/chitiettaikhoan/${id}`);
  }
}
