import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import CapNhatCongViec from "@/pages/CapNhatCongViec.vue";
import ThemDuLieu from "@/pages/ThemDuLieu.vue";
import DsNhanVien from "@/pages/DsNhanVien.vue";
import CapNhatNhanVien from "@/pages/CapNhatNhanVien.vue";
import DangKyNhanVien from "@/pages/DangKyNhanVien.vue";
import TraCuuCongViec from "@/pages/TraCuuCongViec.vue";
import dashboard from "@/pages/Dashboard.vue";
import baoCaoThang from "@/pages/BaoCaoThang.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/capnhatcongviec',
    name: 'capnhatcongviec',
    component: CapNhatCongViec,
    props: true
  },
  {
    path: '/quanlydanhmuc',
    name: 'quanlydanhmuc',
    component: ThemDuLieu,
    props: true
  },
  {
    path: '/dsnhanvien',
    name: 'dsnhanvien',
    component: DsNhanVien,
    props: true
  },
  {
    path: '/capnhatnhanvien/:id',
    name: 'capnhatnhanvien',
    component: CapNhatNhanVien,
    props: true
  },
  {
    path: '/dangkynhanvien',
    name: 'dangkynhanvien',
    component: DangKyNhanVien,
    props: true
  },
  {
    path: '/tracuucongviec',
    name: 'tracuucongviec',
    component: TraCuuCongViec,
    props: true
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    props: true
  },
  {
    path: '/baoCaoThang',
    name: 'baoCaoThang',
    component: baoCaoThang,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
