

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {DashBoard} from "@/models/dashBoard";
import dawacoRepository from "@/services/DawacoRepository";
import {ThoiGian} from "@/models/thoiGian";
import {CongTy} from "@/models/congTy";
import takeColor from "@/utils/color";


export default {
  setup: function () {
    const toast = useToast();
    const date = ref();
    const dashBoard = ref({} as DashBoard);
    const loadingBar = ref(true);
    const listValue = ref([] as CongTy[]);
    const thoiGian = ref({} as ThoiGian);
    const listColor = ref([] as string[]);
    const tongSoHoaDon = ref(0);
    const tongSoTien = ref(0);

    listColor.value = takeColor(30);
    const loadData = () => {
      const temp = ref({} as DashBoard);
      console.log("#######$$$$$$$$$$$$$$$$$$$$$ loadData");
      dawacoRepository.dashBoard()
          .then((response) => {
            dashBoard.value = temp.value;
            dashBoard.value = temp.value;
            console.log("#######$$$$$$$$$$$$$$$$$$$$$ loadData response.data: " + JSON.stringify(response.data));
            dashBoard.value = response.data;
            tongSoHoaDon.value = 0;
            tongSoTien.value = 0;
            if(JSON.stringify(listValue.value) != JSON.stringify(dashBoard.value.thongTinCongTys))
            listValue.value = dashBoard.value.thongTinCongTys;
            loadingBar.value = false;
            listValue.value.forEach(x => {
              tongSoHoaDon.value = tongSoHoaDon.value + x.tongHoaDon;
              tongSoTien.value = tongSoTien.value + x.tongThu;
            })
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          })
          .finally(
              function () {
                setTimeout(loadData, 2 * 1000);
              }
          );
      ;}
      loadData();

      const totalData = computed(() => {
        console.log("################ loadData totalData: ");
        const data = listValue.value;
        console.log("################ loadData data: " + JSON.stringify(data));
        return {
          labels: data.map(x => x.tonG_HOA_DON),
          datasets: [
            {
              label: 'Số hoá đơn',
              data: data.map(x => x.tongHoaDon),
              backgroundColor: listColor.value,
            }
          ]
        }
      });
    const basicOptions = ref(
        {
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );

      return {
        totalData,
        basicOptions,
        date,
        loadingBar,
        tongSoHoaDon,
        tongSoTien,
      }
    }

  }
