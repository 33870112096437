
import LoginPage from "@/pages/LoginPage.vue";
import CongViec from "@/pages/CapNhatCongViec.vue";

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  components: {
    LoginPage,
    CongViec,
   // DanhSachDeTai,
  },
  setup(){
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);
    console.log("##########$$$$$$$$$$$$$$$ log 11111111");

    return {
      isLoggedIn
    }
  }
}
