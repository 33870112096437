

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {NhanVienCreate} from "../models/nhanVienCreate";
import {useStore} from "vuex";
import {NoiHoTro} from "@/models/noiHoTro";
import {CongViec} from "@/models/congViec";
import dawacoRepository from "@/services/DawacoRepository";
import {BaoCaoCreate} from "@/models/baoCaoCreate";
import {BaoCao} from "@/models/baoCao";
import moment from "moment";
import {BaoCaoDetail} from "@/models/baoCaoDetail";
import {FilterMatchMode} from "primevue/api";
import {ListValue} from "@/models/listValue";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const emailPer = ref("");
    emailPer.value = "no";
    const hoTro = ref([] as NoiHoTro[]);
    const idHoTro = ref(0);
    const idLoaiCongViec = ref(0);
    const date = ref();
    const date1 = ref();
    const date2 = ref();
    const loadingBar = ref(false);
    const loadingBarExport = ref(false);
    const idThoiGian = ref("");
    const thoiGian = ref([
      {label: "Cả Ngày", value: "cangay", param: 1},
      {label: "Buổi Sáng", value: "buoisang", param: 2},
      {label: "Buổi Trưa", value: "buoitrua", param: 3},
      {label: "Buổi Chiều", value: "buoichieu", param: 4},
      {label: "Khác", value: "khac", param: 5},
    ]);
    const dsBaoCao = ref([] as BaoCaoDetail[]);
    const dsBaoCaoTemp = ref([] as BaoCaoDetail[]);
    const dsNoiHoTro = ref([] as ListValue[]);
    const noiHoTro = ref();
    const dsLoaiCongViec = ref([] as ListValue[]);
    const loaiCongViec = ref();
    const listCongViec = ref([] as CongViec[])
    const dsThoiGianHoTro = ref([] as ListValue[]);
    const dsNhanVien = ref([] as ListValue[]);
    const thoiGianHoTro = ref();
    const dsMoTa = ref([] as ListValue[]);
    const moTa = ref();
    const nhanVien = ref();
    const baoCao = ref({} as BaoCao);
    const dgBaoCao = ref(false);
    const selectTime = ref(false);

    dawacoRepository.listNoiHoTro()
        .then((response) => {
          hoTro.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    dawacoRepository.listCongViec()
        .then((response) => {
          listCongViec.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    const getBaoCaoById = (id) => {
      dgBaoCao.value = true;
      dawacoRepository.getBaoCaoById(id)
          .then((response) => {
            baoCao.value = response.data;
            date.value = baoCao.value.ngay;
            date1.value = baoCao.value.ngay2;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const updateBaoCao = () => {
      baoCao.value.ngay = date.value;
      baoCao.value.ngay2 = date1.value;
      // console.log("############### cap nhat bao cao baoCao: " + JSON.stringify(baoCao.value));
      dawacoRepository.updateBaoCao(baoCao.value)
          .then((response) => {
            // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% baocao 11112222: ");
            // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% baocao 333333: ");
            // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% baocao 333333 date2: " + JSON.stringify(date2.value));
            if(selectTime.value)
            {
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% baocao aaaaaaa: ");
              selectCalendar();
            }
            else
            {
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% baocao bbbbbb: ");
              selectDefault();
            }
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật thành công báo cáo công việc',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const selectDefault = () => {
      loadingBar.value = true;
      // console.log("################### selectCalendar");
      // if((date2.value[0] != null)&&(date2.value[1] != null))
      {
        dawacoRepository.listBaoCaoDefault(Date.now()/1000)
            .then((response) => {
              dsBaoCaoTemp.value = dsBaoCao.value = response.data;
              dsLoaiCongViec.value = [];
              dsNoiHoTro.value = [];
              dsThoiGianHoTro.value = [];
              dsNhanVien.value = [];
              if(dsBaoCao.value.length > 0){
                dsLoaiCongViec.value.push({value: 'Tất cả'});
                dsNoiHoTro.value.push({value: 'Tất cả'});
                dsThoiGianHoTro.value.push({value: 'Tất cả'});
                dsNhanVien.value.push({value: 'Tất cả'});
              }
              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsNoiHoTro.value.forEach(y => {
                  if(y.value == x.noiHoTro) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.noiHoTro;
                  dsNoiHoTro.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsLoaiCongViec.value.forEach(y => {
                  if(y.value == x.loaiCongViec) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.loaiCongViec;
                  dsLoaiCongViec.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsThoiGianHoTro.value.forEach(y => {
                  if(y.value == x.thoiGian) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.thoiGian;
                  dsThoiGianHoTro.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsMoTa.value.forEach(y => {
                  if(y.value == x.moTaCongViec) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.moTaCongViec;
                  dsMoTa.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsNhanVien.value.forEach(y => {
                  if(y.value == x.nguoiBaoCao) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.nguoiBaoCao;
                  dsNhanVien.value.push(newValue.value);
                }
              });

              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% dsNoiHoTro: " + JSON.stringify(dsNoiHoTro.value));
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% dsLoaiCongViec: " + JSON.stringify(dsLoaiCongViec.value));
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% dsThoiGianHoTro: " + JSON.stringify(dsThoiGianHoTro.value));
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% dsMoTa: " + JSON.stringify(dsMoTa.value));
              // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%% dsNhanVien: " + JSON.stringify(dsNhanVien.value));
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            }).finally(() => {
          loadingBar.value = false;
        });
      }
    };

    const selectCalendar = () => {
      // console.log("################### selectCalendar");
      if((date2.value[0] != null)&&(date2.value[1] != null))
      {
        loadingBar.value = true;
        dawacoRepository.listBaoCao(date2.value[0]/1000, date2.value[1]/1000)
            .then((response) => {
              dsBaoCaoTemp.value = dsBaoCao.value = response.data;
              dsLoaiCongViec.value = [];
              dsNoiHoTro.value = [];
              dsThoiGianHoTro.value = [];
              dsNhanVien.value = [];
              if(dsBaoCao.value.length > 0){
                dsLoaiCongViec.value.push({value: 'Tất cả'});
                dsNoiHoTro.value.push({value: 'Tất cả'});
                dsThoiGianHoTro.value.push({value: 'Tất cả'});
                dsNhanVien.value.push({value: 'Tất cả'});
              }
              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsNoiHoTro.value.forEach(y => {
                  if(y.value == x.noiHoTro) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.noiHoTro;
                  dsNoiHoTro.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsLoaiCongViec.value.forEach(y => {
                  if(y.value == x.loaiCongViec) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.loaiCongViec;
                  dsLoaiCongViec.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsThoiGianHoTro.value.forEach(y => {
                  if(y.value == x.thoiGian) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.thoiGian;
                  dsThoiGianHoTro.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsMoTa.value.forEach(y => {
                  if(y.value == x.moTaCongViec) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.moTaCongViec;
                  dsMoTa.value.push(newValue.value);
                }
              });

              dsBaoCao.value.forEach(x => {
                const check = ref(false);
                dsNhanVien.value.forEach(y => {
                  if(y.value == x.nguoiBaoCao) check.value = true;
                });
                if(!check.value)
                {
                  const newValue = ref({} as ListValue);
                  newValue.value.value = x.nguoiBaoCao;
                  dsNhanVien.value.push(newValue.value);
                }
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            }).finally(() => {
          loadingBar.value = false;
        });
      }
    };

    const clearCalendar = () => {
      // console.log("################### clearCalendar");
    }


    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const clearFilter = () => {
      initFilters();
    };
    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const selectOption = () => {
      dsBaoCao.value = dsBaoCaoTemp.value;
      if(noiHoTro.value != null)
      {
        if(noiHoTro.value != 'Tất cả')
        dsBaoCao.value = dsBaoCao.value.filter(x => x.noiHoTro == noiHoTro.value);
      }
      if(loaiCongViec.value != null)
      {
        if(loaiCongViec.value != 'Tất cả')
        dsBaoCao.value = dsBaoCao.value.filter(x => x.loaiCongViec == loaiCongViec.value);
      }
      if(thoiGianHoTro.value != null)
      {
        if(thoiGianHoTro.value != 'Tất cả')
        dsBaoCao.value = dsBaoCao.value.filter(x => x.thoiGian == thoiGianHoTro.value);
      }
      if(moTa.value != null)
      {
        if(moTa.value != 'Tất cả')
        dsBaoCao.value = dsBaoCao.value.filter(x => x.moTaCongViec == moTa.value);
      }
      if(nhanVien.value != null)
      {
        if(nhanVien.value != 'Tất cả')
        dsBaoCao.value = dsBaoCao.value.filter(x => x.nguoiBaoCao == nhanVien.value);
      }
    }

    const selectOptionTime = (id) => {
      if(id == 1){
        selectTime.value = false;
        selectDefault();
      }
      else{
        selectTime.value = true;
        dsBaoCaoTemp.value = dsBaoCao.value = [];
      }
    }

    const fileName = ref("");
    const downloadFile = ref(false);

    const exportFile = () => {
      loadingBarExport.value = true;
      dawacoRepository.exportFile(dsBaoCao.value)
          .then((response) => {
            fileName.value = response.data;
            toast.add({
              severity: 'success',
              summary: 'Đăng ký',
              detail: 'Xuất tập tin thành công',
              life: 2000
            });
            loadingBarExport.value = false;
            downloadFile.value = true;
            // router.push({
            //   name: 'home'
            // });
          })
          .catch(err => {
            loadingBarExport.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const downLoadFileExport = () => {
      axios({
        url: '../api/import/FileImport/downloadList/' + fileName.value,
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',"BaoCao.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };

    const valid = computed(() => (baoCao.value.idNoiHoTro > 0) && (baoCao.value.idLoaiCongViec > 0) && (baoCao.value.ngay) && (baoCao.value.thoiGian.length > 0) && (baoCao.value.moTaCongViec.length > 0));

    return {
      hoTro,
      loaiCongViec,
      idHoTro,
      idLoaiCongViec,
      date,
      date2,
      selectCalendar,
      clearCalendar,
      thoiGian,
      idThoiGian,
      moTa,
      dsBaoCao,
      formatDateTime,
      filters,
      clearFilter,
      noiHoTro,
      thoiGianHoTro,
      dsLoaiCongViec,
      dsThoiGianHoTro,
      dsMoTa,
      dsNoiHoTro,
      selectOption,
      getBaoCaoById,
      baoCao,
      dgBaoCao,
      updateBaoCao,
      valid,
      dsNhanVien,
      nhanVien,
      listCongViec,
      date1,
      selectOptionTime,
      selectTime,
      loadingBar,
      exportFile,
      downLoadFileExport,
      downloadFile,
      loadingBarExport,
    }
  }
}

