

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {NhanVienCreate} from "../models/nhanVienCreate";
import {useStore} from "vuex";
import {DonVi} from "@/models/donVi";
import DawacoRepository from "@/services/DawacoRepository";
import {PhongBan} from "@/models/phongBan";

export default {

  setup(){
    const router = useRouter();
    const nhanVienCreate = ref({} as NhanVienCreate);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const emailPer = ref("");
    emailPer.value = "no";
    const dsDonVi = ref([] as DonVi[]);
    const dsPhongBan = ref([] as PhongBan[]);
    const dsPhongBanTemp = ref([] as PhongBan[]);
    const idDonVi = ref(0);
    const idPhongBan = ref(0);
    const showDonVi = ref(true);

    const valid = computed(()=> {
      if(nhanVienCreate.value.quyenHan != 'admin') {
        return nhanVienCreate.value.hoVaTen && nhanVienCreate.value.taiKhoan && nhanVienCreate.value.matKhau
            && (idPhongBan.value > 0) && (idDonVi.value > 0);
      }
      else {
        return nhanVienCreate.value.hoVaTen && nhanVienCreate.value.taiKhoan && nhanVienCreate.value.matKhau;
      }
    });

    if(!(store.state.permission == 'admin')&& !(store.state.permission == 'supervisor')){
      router.push({
        name: 'home'
      });
    }

    DawacoRepository.listDonVi()
        .then((response) => {
          dsDonVi.value = response.data;
          console.log("#########$$$$$$$$ dsDonVi: " + JSON.stringify(dsDonVi.value));
          console.log("#########$$$$$$$$ store.state.permission: " + store.state.permission);
          console.log("#########$$$$$$$$ store.state.team: " + store.state.team);
          if(store.state.permission == 'supervisor')
          {
            dsDonVi.value.forEach(x => {
              if(x.tenDonVi == store.state.team){
                idDonVi.value = x.id;
                console.log("#########$$$$$$$$ idDonVi.value: " + idDonVi.value);
                showDonVi.value = false;
                donViSelect();
              }
            })
          }
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    DawacoRepository.listPhongBan()
        .then((response) => {
          console.log("#########$$$$$$$$ DawacoRepository listPhongBan.: " + JSON.stringify(response.data));
          dsPhongBanTemp.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    nhanVienCreate.value.quyenHan = 'user';

    const userPermission = ref([
      {label: "Nhân viên", value: "user", param: 2},
      {label: "Quản lý đơn vị", value: "supervisor", param: 3},
      {label: "Quản lý tổng", value: "admin", param: 1},
    ]);
    const doRegister = () => {
      if(nhanVienCreate.value.matKhau.length < 6)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        dsPhongBan.value.forEach(x => {if(x.id == idPhongBan.value){
          nhanVienCreate.value.phongBan = x.tenPhongBan;
        }});

        dsDonVi.value.forEach(x => {if(x.id == idDonVi.value){
          nhanVienCreate.value.donVi = x.tenDonVi;
        }});
        AuthRepository.dangKyNhanVien(nhanVienCreate.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  router.push({
                    name: 'dsnhanvien'
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    }

    const donViSelect = () => {
      DawacoRepository.listPhongBan()
          .then((response) => {
            console.log("#########$$$$$$$$ DawacoRepository listPhongBan.: " + JSON.stringify(response.data));
            dsPhongBanTemp.value = response.data;
            if(idDonVi.value > 0){
              console.log("#########$$$$$$$$ donViSelect dsPhongBanTemp.value: " + JSON.stringify(dsPhongBanTemp.value));
              dsPhongBan.value = dsPhongBanTemp.value.filter(x => x.idDonVi == idDonVi.value);
              console.log("#########$$$$$$$$ donViSelect dsPhongBan.value: " + JSON.stringify(dsPhongBan.value));
            }
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    return {
      nhanVienCreate,
      doRegister,
      userPermission,
      error,
      emailPer,
      dsPhongBan,
      idDonVi,
      dsDonVi,
      donViSelect,
      idPhongBan,
      valid,
      showDonVi,
    }
  }
}

