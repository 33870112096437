
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();


    const adminMenu = [
        {
          label:'Trang chủ',
          icon:'pi pi-fw pi-home',
          to: '/home'
        },
        {
          label:'Cập nhật công việc',
          icon:'pi pi-fw pi-check-circle',
          to: '/capnhatcongviec'
        },
      {
        label:'Tra cứu công việc',
        icon:'pi pi-fw pi-search',
        to: '/tracuucongviec'
      },
        {
          label:'Quản lý danh mục',
          icon:'pi pi-fw pi-list',
          to: '/quanlydanhmuc'
        },
      {
          label:'Báo cáo tháng',
          icon:'pi pi-fw pi-file',
          to: '/baoCaoThang'
        },
        {
          label:'Đăng ký nhân viên',
          icon:'pi pi-fw pi-user-plus',
          to: '/dangkynhanvien'
        },
        {
          label:'Danh sách nhân viên',
          icon:'pi pi-fw pi-users',
          to: '/dsnhanvien'
        },
      ];

    const userMenu = [
        {
          label:'Trang chủ',
          icon:'pi pi-fw pi-home',
          to: '/home'
        },
      {
        label:'Cập nhật công việc',
        icon:'pi pi-fw pi-check-circle',
        to: '/capnhatcongviec'
      },
      {
        label:'Tra cứu công việc',
        icon:'pi pi-fw pi-search',
        to: '/tracuucongviec'
      },
      {
        label:'Báo cáo tháng',
        icon:'pi pi-fw pi-file',
        to: '/baoCaoThang'
      },
      ];
    const defaultMenu = [
        {
          label:'Trang chủ',
          icon:'pi pi-fw pi-home',
          to: '/home'
        }
      ];

    const appMenu = computed(() => {
      if((store.state.permission == "admin")||(store.state.permission == "supervisor")) return adminMenu;
      else if(store.state.permission == "user") return userMenu;
      else return defaultMenu;
    });

    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      store.dispatch('clearFullName');
      store.dispatch('clearTeam');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout
    }
  }
}
