

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {NhanVienCreate} from "../models/nhanVienCreate";
import {useStore} from "vuex";
import {NoiHoTro} from "@/models/noiHoTro";
import {CongViec} from "@/models/congViec";
import dawacoRepository from "@/services/DawacoRepository";
import {BaoCaoCreate} from "@/models/baoCaoCreate";
import {BaoCao} from "@/models/baoCao";
import moment from "moment";
import {BaoCaoDetail} from "@/models/baoCaoDetail";

export default {

  setup(){
    const toast = useToast();
    const hoTro = ref([] as NoiHoTro[]);
    const loaiCongViec = ref([] as CongViec[]);
    const idHoTro = ref(0);
    const idLoaiCongViec = ref(0);
    const date = ref();
    const date2 = ref();
    const idThoiGian = ref("");
    const thoiGian = ref([
      {label: "Cả Ngày", value: "cangay", param: 1},
      {label: "Buổi Sáng", value: "buoisang", param: 2},
      {label: "Buổi Trưa", value: "buoitrua", param: 3},
      {label: "Buổi Chiều", value: "buoichieu", param: 4},
      {label: "Khác", value: "khac", param: 5},
    ]);
    const moTa = ref("");
    const baoCaoCreate = ref({} as BaoCaoCreate);
    const dsBaoCao = ref([] as BaoCaoDetail[]);

    dawacoRepository.listNoiHoTro()
        .then((response) => {
          console.log("###$$$$$$$$$$$$#@@@@@@ hoTro response.data;: " + JSON.stringify(response.data));
          hoTro.value = response.data;
          console.log("###$$$$$$$$$$$$#@@@@@@ hoTro: " + JSON.stringify(hoTro.value));
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    dawacoRepository.listCongViec()
        .then((response) => {
          console.log("###$$$$$$$$$$$$#@@@@@@ loaiCongViec response.data: " + JSON.stringify(response.data));
          loaiCongViec.value = response.data;
          console.log("###$$$$$$$$$$$$#@@@@@@ loaiCongViec: " + JSON.stringify(loaiCongViec.value));
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });


    // const selectCalendar = () => {
    //   console.log("################### selectCalendar");
    //   if((date2.value[0] != null)&&(date2.value[1] != null))
    //   {
    //     dawacoRepository.listBaoCao(date2.value[0]/1000, date2.value[1]/1000)
    //         .then((response) => {
    //           dsBaoCao.value = response.data;
    //         })
    //         .catch(err => {
    //           toast.add({
    //             severity: 'error',
    //             summary: 'Lỗi',
    //             detail:err.response.data,
    //             life: 2000
    //           });
    //         });
    //   }
    // };

    const clearCalendar = () => {
      console.log("################### clearCalendar");
    }

    const valid = computed(() => (idHoTro.value > 0) && (idLoaiCongViec.value > 0) && (date.value)&& (date2.value) && (idThoiGian.value.length > 0) && (moTa.value.length > 0));

    const addReport = () => {
      baoCaoCreate.value.idNoiHoTro = idHoTro.value;
      baoCaoCreate.value.idLoaiCongViec = idLoaiCongViec.value;
      baoCaoCreate.value.ngay = date.value;
      baoCaoCreate.value.ngay2 = date2.value;
      baoCaoCreate.value.thoiGian = idThoiGian.value;
      baoCaoCreate.value.moTaCongViec = moTa.value;
      dawacoRepository.createBaoCao(baoCaoCreate.value)
          .then((response) => {
            //hoTro.value = response.data;
            const dt = ref();
            const dt2 = ref();
            idHoTro.value = 0;
            idLoaiCongViec.value = 0;
            idThoiGian.value = "";
            moTa.value = "";
            date.value = dt.value;
            date2.value = dt2.value;
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm báo cáo thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };

    return {
      hoTro,
      loaiCongViec,
      idHoTro,
      idLoaiCongViec,
      date,
      date2,
      // selectCalendar,
      clearCalendar,
      thoiGian,
      idThoiGian,
      moTa,
      addReport,
      dsBaoCao,
      formatDateTime,
      valid,
    }
  }
}

