import {AxiosResponse} from "axios";
import Repository from "@/services/Repository";
import {NhanVien} from "@/models/nhanVien";
import {CongViec} from "@/models/congViec";
import {DonVi} from "@/models/donVi";
import {NhanVienCreate} from "@/models/nhanVienCreate";
import {CongViecCreate} from "@/models/congViecCreate";
import {DonViCreate} from "@/models/donViCreate";
import {BaoCaoCreate} from "@/models/baoCaoCreate";
import {NoiHoTroCreate} from "@/models/noiHoTroCreate";
import {PhongBanCreate} from "@/models/phongBanCreate";
import {NoiHoTro} from "@/models/noiHoTro";
import {PhongBan} from "@/models/phongBan";
import {BaoCao} from "@/models/baoCao";
import {ThoiGian} from "@/models/thoiGian";
import {BaoCaoDetail} from "@/models/baoCaoDetail";
import {BaoCaoThangCreate} from "@/models/baoCaoThangCreate";
import {BaoCaoThang} from "@/models/baoCaoThang";

const resource = '/dawaco';

export default new class{
    private Repository: any;
    createNhanVien(nhanVienCreate: NhanVienCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taonhanvien`, nhanVienCreate);
    }
    listNhanVien(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsnhanvien`);
    }
    createCongViec(congViecCreate: CongViecCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taocongviec`, congViecCreate);
    }
    listCongViec(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dscongviec`);
    }
    updateCongViec(congViec: CongViec): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capnhatcongviec`, congViec);
    }
    deleteCongViec(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/xoacongviec/${id}`);
    }
    createDonVi(donViCreate: DonViCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taodonvi`, donViCreate);
    }
    listDonVi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsdonvi`);
    }
    updateDonVi(donVi: DonVi): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capnhatdonvi`, donVi);
    }
    deleteDonVi(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/xoadonvi/${id}`);
    }
    createBaoCao(baoCaoCreate: BaoCaoCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taobaocao`, baoCaoCreate);
    }
    listBaoCao(dt1: number, dt2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsbaocao/${dt1}/${dt2}`);
    }
    listBaoCaoDefault(dt1: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsbaocaodefault/${dt1}`);
    }
    updateBaoCao(baoCao: BaoCao): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capnhatbaocao`, baoCao);
    }
    deleteBaoCao(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/xoabaocao/${id}`);
    }
    createNoiHoTro(noiHoTroCreate: NoiHoTroCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taonoihotro`, noiHoTroCreate);
    }
    listNoiHoTro(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsnoihotro`);
    }
    createPhongBan(phongBanCreate: PhongBanCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taophongban`, phongBanCreate);
    }
    listPhongBan(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsphongban`);
    }
    updatePhongBan(phongBan: PhongBan): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capnhatphongban`, phongBan);
    }
    deletePhongBan(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/xoaphongban/${id}`);
    }
    updateNoiHoTro(noiHoTro: NoiHoTro): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capnhatnoihotro`, noiHoTro);
    }
    deleteNoiHoTro(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/xoanoihotro/${id}`);
    }
    getBaoCaoById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/baocaocongviec/${id}`);
    }
    dashBoard(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/reloadDashBoard`);
    }
    listAward(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListAward`);
    }
    exportFile(list: BaoCaoDetail[]): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/exportFile`, list);
    }
    createBaoCaoThang(baoCaoThangCreate: BaoCaoThangCreate, time: number): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/baoCaoThang/${time}`, baoCaoThangCreate);
    }
    getBaoCaoThang(time: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getBaoCaoThang/${time}`);
    }
    updateBaoCaoThang(baoCaoThang: BaoCaoThang): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/updateBaoCaoThang`, baoCaoThang);
    }
    exportReport(baoCaoThang: BaoCaoThang, time: number): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/exportReport/${time}`, baoCaoThang);
    }
}