

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {NhanVienCreate} from "../models/nhanVienCreate";
import {useStore} from "vuex";
import dawacoRepository from "@/services/DawacoRepository";
import {NoiHoTroCreate} from "@/models/noiHoTroCreate";
import {CongViecCreate} from "@/models/congViecCreate";
import {DonVi} from "@/models/donVi";
import {DonViCreate} from "@/models/donViCreate";
import {PhongBanCreate} from "@/models/phongBanCreate";
import {NoiHoTro} from "@/models/noiHoTro";
import {CongViec} from "@/models/congViec";
import {PhongBan} from "@/models/phongBan";
import {useConfirm} from "primevue/useconfirm";

export default {

  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const noiHoTro = ref({} as NoiHoTroCreate);
    const loaiCongViec = ref({} as CongViecCreate);
    const donVi = ref({} as DonViCreate);
    const listDonVi = ref([] as DonVi[]);
    const phongBan = ref({} as PhongBanCreate);
    const idDonVi = ref(0);
    const listNoiHoTro = ref([] as NoiHoTro[]);
    const dgNoiHoTro = ref(false);
    const noiHoTroUpdate = ref({} as NoiHoTro);
    const listCongViec = ref([] as CongViec[]);
    const dgCongViec = ref(false);
    const congViecUpdate = ref({} as CongViec);
    const dgDonVi = ref(false);
    const donViUpdate = ref({} as DonVi);
    const listPhongBan = ref([] as PhongBan[]);
    const dgPhongBan = ref(false);
    const phongBanUpdate = ref({} as PhongBan);
    const idDV = ref(0);


    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    const getListNoiHoTro = () => {
      dawacoRepository.listNoiHoTro()
          .then((response) => {
            listNoiHoTro.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    const getListCongViec = () => {
      dawacoRepository.listCongViec()
          .then((response) => {
            listCongViec.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    getListNoiHoTro();
    getListCongViec();

    const themNoiHoTro = () => {
      dawacoRepository.createNoiHoTro(noiHoTro.value)
          .then((response) => {
            noiHoTro.value.diaChi = "";
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm hỗ trợ thành công',
              life: 2000
            });
            getListNoiHoTro();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    };

    const themCongViec = () => {
      dawacoRepository.createCongViec(loaiCongViec.value)
          .then((response) => {
            loaiCongViec.value.loaiCongViec = "";
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm công việc thành công',
              life: 2000
            });
            getListCongViec();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const themDonVi = () => {
      dawacoRepository.createDonVi(donVi.value)
          .then((response) => {
            donVi.value.tenDonVi = "";
            dsDonVi();
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm đơn vị thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    };

    const themPhongBan = () => {
      phongBan.value.idDonVi = idDonVi.value;
      dawacoRepository.createPhongBan(phongBan.value)
          .then((response) => {
            phongBan.value.tenPhongBan = "";
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm phòng ban thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    };

    const dsDonVi = () => {
      dawacoRepository.listDonVi()
          .then((response) => {
            listDonVi.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    };

    const selectNoiHoTro = (id) => {
      dgNoiHoTro.value = true;
      listNoiHoTro.value.filter(x =>{
        if(x.id == id)
        {
          noiHoTroUpdate.value = x;
        }
      })
    }

    const updateNoiHoTro = () => {
      dawacoRepository.updateNoiHoTro(noiHoTroUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật nơi hỗ trợ thành công',
              life: 2000
            });
            dgNoiHoTro.value = false;
            getListCongViec();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteCongViec = (id) =>{
      dawacoRepository.deleteCongViec(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xoá công việc thành công',
              life: 2000
            });
            getListCongViec();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    const selectCongViec = (id) => {
      dgCongViec.value = true;
      listCongViec.value.filter(x =>{
        if(x.id == id)
        {
          congViecUpdate.value = x;
        }
      })
    }

    const updateCongViec = () => {
      dawacoRepository.updateCongViec(congViecUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật công việc thành công',
              life: 2000
            });
            getListNoiHoTro();
            dgCongViec.value = false;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteNoiHoTro = (id) =>{
      dawacoRepository.deleteNoiHoTro(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xoá nơi hỗ trợ thành công',
              life: 2000
            });
            getListNoiHoTro();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const dv = ref("");
    const selectDonVi= (id) => {
      dgDonVi.value = true;
      listDonVi.value.forEach(x =>{
        if(x.id == id)
        {
          donViUpdate.value = x;
          dv.value = x.tenDonVi;
        }
      })
    }

    const updateDonVi = () => {
      donViUpdate.value.tenDonVi = dv.value;
      dawacoRepository.updateDonVi(donViUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật đơn vị thành công',
              life: 2000
            });
            dgDonVi.value = false;
            dsDonVi();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteDonVi = (id) =>{
      dawacoRepository.deleteDonVi(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xoá đơn vị thành công',
              life: 2000
            });
            dsDonVi();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const pb = ref("");

    const selectPhongBan= (id) => {
      dgPhongBan.value = true;
      listPhongBan.value.forEach(x =>{
        if(x.id == id)
        {
          phongBanUpdate.value = x;
          pb.value = x.tenPhongBan;
        }
      })
    }

    const getListPhongBan = (id) => {
      dawacoRepository.listPhongBan()
          .then((response) => {
            listPhongBan.value = response.data.filter(x => x.idDonVi == id);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const updatePhongBan = () => {
      phongBanUpdate.value.tenPhongBan = pb.value;
      dawacoRepository.updatePhongBan(phongBanUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật phòng ban thành công',
              life: 2000
            });
            dgPhongBan.value = false;
            getListPhongBan(phongBanUpdate.value.idDonVi);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deletePhongBan = (id) =>{
      dawacoRepository.deletePhongBan(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xoá phòng ban thành công',
              life: 2000
            });
            if(listPhongBan.value.length > 0)
            getListPhongBan(listPhongBan.value[0].idDonVi);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    dsDonVi();
    const confirm = useConfirm();
    const delNoiHoTro = (event, id) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá nơi hỗ trợ, những dữ liệu liên quan sẽ bị xoá theo?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          deleteNoiHoTro(id);
        },
        reject: () => {
        }
      });
    };
    const delCongViec = (event, id) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá công việc, những dữ liệu liên quan sẽ bị xoá theo?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          deleteCongViec(id);
        },
        reject: () => {
        }
      });
    };

    const delDonVi = (event, id) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá đơn vị, những dữ liệu liên quan sẽ bị xoá theo?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          deleteDonVi(id);
        },
        reject: () => {
        }
      });
    };

    const delPhongBan = (event, id) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá phòng ban, những dữ liệu liên quan sẽ bị xoá theo?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          deletePhongBan(id);
        },
        reject: () => {
        }
      });
    };

    return {
      noiHoTro,
      loaiCongViec,
      themNoiHoTro,
      themCongViec,
      themDonVi,
      themPhongBan,
      donVi,
      phongBan,
      idDonVi,
      listDonVi,
      listNoiHoTro,
      noiHoTroUpdate,
      dgNoiHoTro,
      selectNoiHoTro,
      updateNoiHoTro,
      deleteNoiHoTro,
      selectCongViec,
      updateCongViec,
      deleteCongViec,
      listCongViec,
      congViecUpdate,
      dgCongViec,
      selectDonVi,
      updateDonVi,
      deleteDonVi,
      donViUpdate,
      dgDonVi,
      dv,
      getListPhongBan,
      updatePhongBan,
      deletePhongBan,
      pb,
      idDV,
      listPhongBan,
      selectPhongBan,
      dgPhongBan,
      delNoiHoTro,
      delCongViec,
      delPhongBan,
      delDonVi,
    }
  }
}

