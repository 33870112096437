
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserDetail} from "@/models/userDetail";
import {NhanVien} from "@/models/nhanVien";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as NhanVien[]);
    const store = useStore();

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    if(!(store.state.permission == 'admin')&& !(store.state.permission == 'supervisor')){
      router.push({
        name: 'home'
      });
    }

    AuthRepository.dsNhanVien()
        .then((response) => {
          list.value = response.data;
          console.log("###########@@@@@@@@@ dsNhanVien: " + JSON.stringify(list.value));
          list.value.forEach(x => {
            if(x.quyenHan == "admin") x.quyenHan = "Quản lý";
            else if(x.quyenHan == "user") x.quyenHan = "Nhân viên";
          })
        })
        .catch();



    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
    }
  }
}
