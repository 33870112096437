<template>
  <div className="app-container">
    <Toast/>
    <TopNavBar :isLoggedIn="isLoggedIn"/>
    <div className="app-content">
      <router-view/>
    </div>
    <div>
<!--      <img alt="logo" src="logo.png" height="30" className="">-->
      <div class="footer">
      <b style="alignment: center; color: dimgray">CÔNG TY CỔ PHẦN CẤP NƯỚC ĐÀ NẴNG</b>
      <br>
      <b style="alignment: center; color: dimgray">Địa chỉ: 57 Xô Viết Nghệ Tĩnh - P. Hòa Cường Nam, Q. Hải Châu, TP. Đà Nẵng</b>
      <br>
      <b style="alignment: center; color: dimgray">Điện thoại: 0236.369.6632 - 0236 697 506 - Email: dawacojsc@gmail.com - Website: www.dawaco.com.vn</b>
      </div>
      </div>
  </div>
  <div>
  </div>
</template>


<!--<style lang="scss">-->
<!--.app-container {-->
<!-- margin: 0 auto;-->
<!-- max-width: 1400px;-->
<!-- width: 100%;-->
<!-- height: max-content;-->
<!--background: #F7F8F9;-->
<!--  .app-content {-->
<!--   margin: 8px;-->
<!--  }-->
<!--}-->
<!--</style>-->
<style lang="scss">
@import 'assets/style.css';
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
</script>
