

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {NhanVien} from "@/models/nhanVien";
import DawacoRepository from "@/services/DawacoRepository";
import {DonVi} from "@/models/donVi";
import {PhongBan} from "@/models/phongBan";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const nhanVien = ref({} as NhanVien);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const dsDonVi = ref([] as DonVi[]);
    const dsPhongBan = ref([] as PhongBan[]);
    const dsPhongBanTemp = ref([] as PhongBan[]);
    const idDonVi = ref(0);
    const idPhongBan = ref(0);
    // userUpdate.value.password = "";
    console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% chiTietNhanVien props.id: " + JSON.stringify(props.id));

    AuthRepository.chiTietNhanVien(props.id)
    .then((response) => {
      nhanVien.value = response.data;
      nhanVien.value.matKhau = "";

      DawacoRepository.listDonVi()
          .then((response) => {
            dsDonVi.value = response.data;
            DawacoRepository.listPhongBan()
                .then((response) => {
                  dsPhongBan.value = dsPhongBanTemp.value = response.data;
                  dsDonVi.value.forEach(x => {
                    if(x.tenDonVi == nhanVien.value.donVi) idDonVi.value = x.id;
                  });
                  dsPhongBan.value = dsPhongBanTemp.value.filter(x => (x.idDonVi == idDonVi.value));
                  dsPhongBan.value.forEach(x => {
                    if(x.tenPhongBan == nhanVien.value.phongBan) idPhongBan.value = x.id;
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
      console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% chiTietNhanVien nhanVien: " + JSON.stringify(nhanVien.value));
    })
    .catch(err => {
      toast.add({
        severity: 'error',
        summary: 'Lỗi',
        detail:err.response.data,
        life: 2000
      });
    });

   // const valid = computed(()=> nhanVien.value.taiKhoan && nhanVien.value.hoVaTen);
    const valid = computed(()=> {
      if(nhanVien.value.quyenHan != 'admin') {
        return nhanVien.value.hoVaTen && nhanVien.value.taiKhoan
            && (idPhongBan.value > 0) && (idDonVi.value > 0);
      }
      else {
        return nhanVien.value.hoVaTen && nhanVien.value.taiKhoan;
      }
    });

    if(!(store.state.permission == 'admin')&& !(store.state.permission == 'supervisor')){
      router.push({
        name: 'home'
      });
    }

    const userPermission = ref([
      {label: "Nhân viên", value: "user", param: 2},
      {label: "Quản lý đơn vị", value: "supervisor", param: 3},
      {label: "Quản lý tổng", value: "admin", param: 1},
    ]);

    const doUpdate = () => {
      // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 userUpdate: " + JSON.stringify(userUpdate.value));
      // console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 userUpdate.value.password.length: " + JSON.stringify(userUpdate.value.password.length));
      if(nhanVien.value.matKhau.length < 6 && nhanVien.value.matKhau.length > 0)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        dsPhongBan.value.forEach(x => {if(x.id == idPhongBan.value){
          nhanVien.value.phongBan = x.tenPhongBan;
        }});

        dsDonVi.value.forEach(x => {if(x.id == idDonVi.value){
          nhanVien.value.donVi = x.tenDonVi;
        }});
        AuthRepository.capNhatNhanVien(nhanVien.value)
                .then((response) => {
                  console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(nhanVien.value));
                  toast.add({
                    severity: 'success',
                    summary: 'Cập nhật',
                    detail: 'Cập nhật thông tin tài khoản thành công',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    };
    const confirm = useConfirm();
    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá tài khoản này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          AuthRepository.xoaNhanVien(nhanVien.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
                router.push({
                  name: 'dsnhanvien',
                });
              })
              .finally(()=>{
              });
        },
        reject: () => {
        }
      });
    };

    const donViSelect = () => {
      if(idDonVi.value > 0){
        dsPhongBan.value = dsPhongBanTemp.value.filter(x => x.idDonVi == idDonVi.value);
      }
    }

    return {
      nhanVien,
      doUpdate,
      userPermission,
      error,
      del,
      idDonVi,
      idPhongBan,
      dsPhongBan,
      dsDonVi,
      donViSelect,
      valid
    }
  }
}

