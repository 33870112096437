

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {NhanVienCreate} from "../models/nhanVienCreate";
import {useStore} from "vuex";
import {DonVi} from "@/models/donVi";
import DawacoRepository from "@/services/DawacoRepository";
import {PhongBan} from "@/models/phongBan";
import {BaoCaoThangCreate} from "@/models/baoCaoThangCreate";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const baoCaoThangCreate = ref({} as BaoCaoThangCreate);
    const date = ref();
    const fileName = ref();
    const keHoach = ref("");
    const kienNghi = ref("");

    baoCaoThangCreate.value.keHoach=" ";
    baoCaoThangCreate.value.kienNghi=" ";

    const doReport = () => {
      fileName.value="";
      console.log("#####debug report")
      console.log("#####debug report : " + JSON.stringify(baoCaoThangCreate.value));
      // console.log("#####debug report baoCaoThangCreate.keHoach: " + keHoach.value);
      // console.log("#####debug report baoCaoThangCreate.kienNghi: " + kienNghi.value);
      //baoCaoThangCreate.value.keHoach = keHoach.value;
     // baoCaoThangCreate.value.kienNghi = kienNghi.value;
      DawacoRepository.createBaoCaoThang(baoCaoThangCreate.value, date.value/1000)
      .then((response) => {
        fileName.value = response.data;
        toast.add({
          severity: 'success',
          summary: 'Thành công',
          detail: 'Tạo báo cáo thành công',
          life: 2000
        });
      })
      .catch(err => {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:err.response.data,
          life: 2000
        });
      });
    }

    const selectMonth = () => {
      DawacoRepository.getBaoCaoThang(date.value/1000)
          .then((response) => {
            console.log("#####debug selectMonth response.data: " + response.data);
            // if(response.data != null)
            baoCaoThangCreate.value = response.data;
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:err.response.data,
            //   life: 2000
            // });
          });
    }

    const downloadFile = () => {
      axios({
        url: 'api/dawaco/downloadReport/'+fileName.value,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/msword'}));
        var fileLink = document.createElement('a');


        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName.value + ".doc");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };


    return {
      doReport,
      date,
      baoCaoThangCreate,
      selectMonth,
      downloadFile,
      keHoach,
      kienNghi,
      fileName,
    }
  }
}

